body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url('/public/cdr.png') contain no-repeat center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo{
	float: left;
	display:block;
	width:32px;
	height: 32px;
}

.clearfix{
	clear: both;
	display: block;
	width:100%;
	height: 10px; 
}

.nounderline{
	text-decoration: none !important;
}

.homegridimage{
	width: 200px;
	height: 200px;
	margin: 0 auto;
	display: block;
}
.imgContainer{
	width: 100%;
	height: 200px;
	background: url('/public/cdr.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center top;
}

.imgContainer-phonelookup {
	width: 100%;
	height: 200px;
	background: url('/public/phone-lookup.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center top;
}

.imgContainer-iplookup {
	width: 100%;
	height: 200px;
	background: url('/public/ipcheck.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center top;
}
.imgContainer-maximind {
	width: 100%;
	height: 200px;
	background: url('/public/maximind.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
}
.imgContainer-search {
	width: 100%;
	height: 200px;
	background: url('/public/search.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
}
.imgContainer-dvr {
	width: 100%;
	height: 200px;
	background: url('/public/dvrtool.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
}

.imgContainer-utc {
	width: 100%;
	height: 200px;
	background: url('/public/utc.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
}

.imgContainer-tower {
	width: 100%;
	height: 200px;
	background: url('/public/tower.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
}

.imgContainer-common {
	width: 100%;
	height: 200px;
	background: url('/public/common.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
}